/**
 * 表单配置
 */
 const formConf = {
    formRef: 'elForm',
    formModel: 'form',
    rules: 'rules',
    size: 'medium',
    labelPosition: 'right',
    labelWidth: 80,
    formRules: 'rules',
    gutter: 15,
    disabled: false,
    dynamicTableAllowed:true
  };
  export default formConf;