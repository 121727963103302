<template>
    <div v-show="props.compType === 'upload'">
        <el-form-item label="标题">
            <el-input class="input" v-model="props.label"></el-input>
        </el-form-item>
        <el-form-item label="栅格">
            <el-input-number v-model="props.span" :min="1" :max="24" />
        </el-form-item>
        <!-- <el-form-item label="提示符">
            <el-input class="input" v-model="props.placeholder" placeholder="请输入提示符" />
        </el-form-item> -->
        <!-- <el-form-item label="栅格间隔">
            <el-input-number v-model="props.gutter" :min="0"></el-input-number>
        </el-form-item> -->
        <el-form-item label="标签宽度">
            <el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
        </el-form-item>
        <el-form-item label="大小(MB)">
            <el-input-number v-model="props.fileSize" :min="1" :max="10" :step="10" />
        </el-form-item>
        <!-- <el-form-item label="允许文件类型">
            <el-input v-model="props.accept"></el-input>
        </el-form-item> -->
        <el-form-item label="必填">
            <el-switch v-model="props.required"></el-switch>
        </el-form-item>
        <el-form-item v-if="!props.isOne" label="最多上传几张">
            <el-input-number v-model="props.few" :min="1" :max="9"></el-input-number>
        </el-form-item>
    </div>
</template>
<script>
    import {
        changeId
    } from '../mixin'
    export default {
        name: "uploadConfig",
        props: ['props'],
        components: {},
        mixins: [changeId],
        data() {
            return {}
        },
        methods: {
        },
        mounted() {},
        watch: {}
    }

</script>
<style lang="scss" scoped>
    .input {
        width: 75%
    }
    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }
</style>
