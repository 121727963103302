export default {
    slot(h,conf) {
        const list = [];
        if (conf.compType === 'attachmentUpload') { // 附件上传组件
            list.push(<el-button type="primary" icon="el-icon-plus">附件上传</el-button>)
        } else if (conf['list-type'] === 'picture-card') {
            list.push(<i class="el-icon-plus"/>)
        } else {
            list.push(<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAfNJREFUaEPtWsFKw0AQnSn0JHrozZ8wgh5UCmZT/A496Vl/wPQH9KwX63dIsxGK9aBg/AlvPSieCh2ZkIRUQrLYpklw9prZ2ffezLI7k0XP8w4R0QYAF5o1XCLyUWt9BwAnzcKeoB0wAUqBfyaiMRF91pEQIm4g4j4A7MX4EgJE9K6U2kbENKHa8SAixvyGiFsMLiGAiLe2bZ/VDnEGIN/3b4jodI7AbDbr93q9Rmzk4XDotlqtSyFQVbpJBKpSPl5XIvAvIhAEwdpkMnlgsp1O58iyrO9lEV9JCmmt+YKoI9BKKeULgUgBiYBJKkgK5agkKSQpZKKApFCOAtzJKBIRETF9kBFRYanqOM5jkV/+vtAm1lq/AMCOyUJ/sHlVSu0WzcskwH0hpVS/aLLv+09ExJ2BpQ9EHNu2fVDkWGvN5WRY/qbbKkYEeFJ0SBWtE5rGdyETY9P70sIETMCUeRILAYmAiQI5NpJCJgLKJpYUMsmTfJVKK+pXsolHo9H6dDoNT+J2u6263e7Xgpok01dCYFlgs/wIgTLVNfEtETBRqUwbiUCZ6pr4zowAEV07jnNh4qBqG8/zrhDxfK6kNK1HqwbP66fr8t9PDQIAeAaAjzoAzcCwGT0zsOJvTICr+/CncQOHywT40sWvVY4bRuAeAAY/nYhOLeUeM58AAAAASUVORK5CYII="/>)
        }
        if (conf.showTip) {
            list.push(<div slot="tip" class="el-upload__tip">{conf.tips}</div>)
        }
        console.log(list,'list1111')
        return list
    }
}
